
.main-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    align-items: center;
    justify-content: center;

    .card-header {
        background-color: rgba(120, 11, 32, 1);
        color: white;
        font-size: medium;
    }

    .request-frame {
        padding: 30px;
        width: 40%;
        max-width: 500px;
        object-fit: contain;
    }
    
    .request-container {
        display: flex;
        flex-direction: column;
    
        .screen-title {
            display: flex;
            flex-direction: row;
            font-size: 20px;
            align-self: center;
        }
    
        .info-text {
            font-size: 15px;
            width: 80%;
            margin-top: 10px;
            position: relative;
            align-self: center;
        }
    
        .request-form {
            display: flex;
            flex-direction: column;
            width: 80%;
            position: relative;
            align-self: center;
            margin-bottom: 10px;
            justify-content: space-between;
    
            #e-mail-input {
                margin-bottom: 10px;
                height: 40px;
            }
    
            .slim-btn {
                height: 40px;
            }
        }
    
        .already-confirmed-text {
            display: flex;
            flex-direction: row;
            font-size: 15px;
            margin-top: 10px;
            width: 80%;
            position: relative;
            align-self: center;
    
            #click-here-btn {
                height: 40px;
                position: relative;
                top: 15px;
                margin-left: 10px;
            }
        }
    }
}


@media only screen and (max-width: 1050px) {
    .request-frame {
        min-width: 455px;
    }
}

@media only screen and (max-width: 600px) {

    .request-frame {
        
        padding: 30px;
        min-width: 400px;
    }

    .form-container {
        min-height: 450px;
        max-height: 520px;
        top: 10%;
    }
}