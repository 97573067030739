.page-wrapper {
    height: 0;
    display: flex;
    width: 100%;
    background-size: cover;
    background-position: center;
    overflow-y: hidden;

    .content-wrapper {
        padding: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        max-height: 100%;
    }

    .character-wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column-reverse;
        justify-content: flex-start;
    }

    .character-wrapper img {
        width: auto;
        height: 80%;
        object-fit: contain;
    }

    .row {
        width: 100%;
        height: 100%;
    }

    .scrollableBody {
        max-height: 90%;
    }

    .card {
        min-width: 40%;
    }

    .scrollableBody>.card-body {
        overflow-y: scroll;
    }
}