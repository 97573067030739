body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body,
html {
  height: 100vh !important;
}


html,
body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 0;
}

#root .page-layout-row {
  border: 1px dotted grey;
}

#root header {
  width: 100%;
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

#root section.page-layout-row {
  flex: 1 1 auto;
}

#root footer {
  flex: 0 1 40px;
  display: none;
}