.mainContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    align-items: center;
}

.form-container {
    font-size: 20px;
    width: 25rem;
    position: relative;
    top: 25%;
    animation-name: fadeIn;
    animation-duration: 2s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    opacity: 0;
}

.form-container .card-body {
    font-size: 20px;
    width: 80%;
    position: relative;
    align-self: center;
    margin: auto;
    justify-content: space-between;
}

.screen-title {
    text-align: center;
    align-items: left;
}

.logo {
    width: 80px;
    height: 80px;
}

.dividing-line {
    margin-top: 10px;
    margin-bottom: 10px;
    height: 3px;
    background-color: black
}

.login-text {
    font-size: medium;
}

.button-container {
    display: flex;
    flex-direction: column;
    max-width: 300px;

}
.ms-button {
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: white;
    border-color: grey;
    color: black;
}

.google-button {
    margin-top: 10px;
    margin-bottom: 10px;
}

a.nav-link.active#sign-up,
a.nav-link.active#log-in {
    background-color: rgba(120, 11, 32, 1);
    color: white;
}

@media screen and (max-width: 450px) {
    .form-container .card-body {
        width: 80%;
        
    }

    .form-container {
        top: 10%;
        width: 20rem;
        max-height: 300px;
    }
}

@media screen and (max-width: 1050px) {
    .form-container .card-body {
        margin-top: 0px;
        margin-bottom: 0px;
        max-height: 340px;
    }

    .form-container {
        top: 15%;
        max-height: 300px !important;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}