.mainContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative
}

.town-img {
    height: auto;
    height: 100vh;
    width: 100vw;
    object-fit: cover;
    object-position: 50% bottom;
}

.smith-img {
    height: 40%;
    max-width: 70%;
    object-fit: contain;
    position: fixed;
    left: 3%;
    bottom: 0%;
}

.smith-bubble {
    right: auto;
    left: 5%;
    bottom: 45%;
    position: fixed;
}

.trader-img {
    height: 30%;
    max-width: 70%;
    object-fit: contain;
    position: fixed;
    left: 25%;
    bottom: 3%;
}

.trader-bubble {
    right: auto;
    left: 25%;
    bottom: 38%;
    position: fixed;
}

.barkeeper-img {
    height: 22%;
    max-width: 70%;
    object-fit: contain;
    position: fixed;
    right: 30%;
    bottom: 3%;
}

.barkeeper-bubble {
    right: 30%;
    bottom: 30%;
    left: auto;
    position: fixed;
}

.bard-img {
    height: 30%;
    max-width: 70%;
    object-fit: contain;
    position: fixed;
    right: 10%;
    bottom: 3%;
}

.bard-bubble {
    right: 12%;
    bottom: 35%;
    left: auto;
    position: fixed;
}

.mage-img {
    height: 15%;
    max-width: 70%;
    object-fit: contain;
    position: fixed;
    left: 50%;
    bottom: 3%;
    right: auto;
}

.mage-bubble {
    right: auto;
    left: 54%;
    bottom: 12%;
    position: fixed;
}

.town-bubble {
    display: none;
}

a:hover > .town-bubble {
    display: block;
}

.zoom {
    transition: transform .2s;
  }
  
.zoom:hover {
    transform: scale(1.2);
}

.content {
    position: fixed;
    top: 0;
    z-index: -1;
    width: 100%;
}