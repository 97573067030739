.LlmUsageComponent {

    margin-top: 5rem;

    .model-cards {
        margin-top: 3rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .model-card {
        width: 48%;
    }

    .right {
        text-align: right;
    }
}