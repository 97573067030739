.vertical-scroll-container {
    width: 100%;
    overflow-x: scroll;
    padding-block: 1rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;

    .card {
        height: 23rem;
        width: 20rem;
    }

    .card-body {
        align-self: center;
        justify-self: center;
        height: 20rem;
        width: 20rem;
        object-fit: cover;
    }

    .card-img-top {
        align-self: center;
        justify-self: center;
        height: 20rem;
        width: 20rem;
        object-fit: cover;
    }
}

.tale-region-view {
    .region-section {
        margin-top: 2rem;
        padding: 1rem;
        background-color: rgb(237 237 237);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 40;
        border-radius: 10px;
    }

    .text-editing>p {
        background-color: rgb(237 237 237);
        padding: 1rem;
        margin-bottom: 0.5rem;
        border-radius: 10px;
    }
}