.beta-component {
    .amountInfo {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 2rem;
        font-size: larger;
        margin-top: 3rem;
    }

    .update-ceil {
        padding-block: 0.1rem;
    }
}