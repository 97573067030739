$red: #780b20;

$primary: $red;

.navbar {
    height: 100px;
}

.navbar-nav>a,
.navbar-brand>div,
.navbar-text {
    color: white !important;
}

.navbar-toggler,
.navbar-toggler-icon {
    border-color: white;
}

.content {
    height: fit-content;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@import '~bootstrap/scss/bootstrap.scss';