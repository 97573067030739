.quote-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 10px;
    border-radius: 1%;
    border-style:ridge;
    border-color: rgba(120, 11, 32, 1);
    border-radius: 10px;

    #quote-title-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .quote-title {
        padding-left: 30%;
        font-weight: bold;
        
    }

    .quote-text {
        width: 300px;
        font-size: large;
        font-style: italic;
        margin-left: 1rem;
        margin-right: 1rem; 
        align-self: center;
    }

    .quote-author {
        width: 200px;
        margin-left: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .new-quote-button {
        align-self: flex-end;
        margin-left: auto;

    }

    #reload-icon {
        width: 20px;
        height: 20px;
    }
    
    @media only screen and (max-width: 600px) {
        
            p {
                
                font-size: small;
            }

            .quote-text {
                font-size: small;
                max-width: 200px;
            }
        
    }
    
}