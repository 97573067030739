.beta-queue {
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    align-items: center;
    justify-content: center;

  .queue-frame {
    
    .card-header {
        background-color: rgba(120, 11, 32, 1);
        color: white;
        font-size: medium;
    }

    .queue-container {
        display: flex;
        flex-direction: column;
        max-width: 500px;
        
        .screen-title {
            display: flex;
            flex-direction: row;
            font-size: 20px;
            align-self: center;

            .welcome-message {
                margin-bottom: auto;
                margin-top: 10px;
            }
        }
        .text-container {
            display: flex;
            flex-direction: column;
            margin-right: 2rem;
            margin-left: 2rem;

            .thank-you-text {
                font-size: large;
                font-weight: 750;
                align-self: center;
            }
    
            .info-text {
                font-size: medium;
                align-self: center;
            }
        }
        
    }

    #back-button {
        margin-top: 1rem;
    }
  }  
}

@media only screen and (max-width: 600px) {
    .beta-queue {
        .queue-frame {
            max-width: 350px;
            max-height: 80svh;
        }

        .logo {
            width: 50px;
            height: 50px;
        }
    
        .welcome-message {
            font-size: medium;
        }
    
        p {
            font-size: small !important;
        }
    
    }
    
}