.TaleNavigation {

    width: fit-content;
    height: 100%;
    display: flex;
    width: 25%;
    color: white;
    background-color: #414141;
    padding-top: 1rem;
    padding-inline: 2rem;
    font-size: x-large;
    flex-direction: column;

    .icon-holder {
        width: 2rem;
    }

    .nav-heading {
        margin-bottom: 1rem;
    }

    a {
        flex-direction: row;
        color: white;
        text-decoration: none;
        display: flex;
        width: fit-content;
        align-items: center;
        line-height: normal;
    }
}